import { createSlice } from '@reduxjs/toolkit';

const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState: {
    searchResults: [],
    searchTerm: '',
    loading: false,
    activeButton: 'blogs',
    formOrder: null,
  },
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSearchLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActiveButton: (state, action) => {
      state.activeButton = action.payload;
    },
    setFormOrder: (state, action) => {
      state.formOrder = action.payload;
    },
  },
});

export const { actions, reducer } = searchResultsSlice;
