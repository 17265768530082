// interceptor.js
const requestInterceptor = async (options) => {
  const token = localStorage.getItem("token");
  //console.log("Token:", token);

  if (token) {
    options.headers = {
      ...options.headers,
      token: token,
    };
  }

  //console.log('option ', options);
  return options;
};

const responseInterceptor = async (response) => {
  //console.log("Response interceptor is running...", response);

  if (response.ok) {
    try {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        // Parse JSON using .json() method
        const responseData = await response.json();
        return responseData;
      } else {
        // Return the entire response for non-JSON responses
        return response;
      }
    } catch (error) {
      console.error("Error parsing response:", error);
      throw new Error("Error parsing response");
    }
  } else {
    // Check if response is unauthorized (status code 401 or 403) or token expired
    if (response.status === 401 || response.status === 403) {
      // Remove token, username, and email from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");

      // Redirect user to login page
      window.location.href = "/login";
    }
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
};

export default {
  request: requestInterceptor,
  response: responseInterceptor,
};
