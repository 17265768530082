import React, { useState } from "react";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./login.css";
import { useHistory } from "react-router-dom";
import { ADD_MESSAGE } from "../../components/Redux/actions";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = ({ addMessage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const isInvalidPassword = (pwd) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  const isInvalidEmail = (email) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  const history = useHistory();

  const handleLogin = () => {
    setLoadingState(true);
    setErrorMessage("");

    let message = "";

    if (isInvalidEmail(email)) {
      message = "Invalid email or password. Please try again.";
    } else if (isInvalidPassword(password)) {
      message = "Invalid email or password. Please try again.";
    }

    if (message) {
      addMessage(message);
      setLoadingState(false);
      return;
    }

    //console.log("Logging in...");

    postCall()(getBaseApi() + BackendAPIs.auth.login, {
      email,
      password,
    })
      .then((resp) => {
        setLoadingState(false);
        //console.log("Response:", resp);
        if (resp.data) {
          localStorage.setItem("token", resp.data.jwt);
          localStorage.setItem("name", resp.data.name);
          localStorage.setItem("email", resp.data.email);
          addMessage("Login successful!");

          const redirectUrlFromCookies = document.cookie
            .split("; ")
            .find((row) => row.startsWith("redirectUrl"))
            ?.split("=")[1];

          if (redirectUrlFromCookies) {
            const decodedURL = decodeURIComponent(redirectUrlFromCookies);

            const cookieDomain =
              window.location.hostname === "localhost"
                ? "localhost"
                : ".ownsit.xyz";

            document.cookie = `token=${resp.data.jwt}; Domain=${cookieDomain}; Secure; SameSite=Lax; path=/`;

            // if (window.location.hostname === "localhost") {
            //   document.cookie = `token=${resp.data.jwt}; path=/`;
            // } else {
            //   document.cookie = `token=${resp.data.jwt}; Domain=shop.ownsit.xyz; Secure; SameSite=Lax; path=/`;
            // }

            // document.cookie = `token=${resp.data.jwt}; path=/`;

            window.location.href = decodedURL;

            const cookieDomainExp =
              window.location.hostname === "localhost"
                ? "localhost"
                : "ownsit.xyz";

            document.cookie = `redirectUrl=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${cookieDomainExp};`;

            // if (window.location.hostname === "localhost") {
            //   document.cookie =
            //     "redirectUrl=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            // } else {
            //   document.cookie =
            //     "redirectUrl=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=ownsit.xyz; Secure; SameSite=Lax; path=/";
            // }

            document.cookie =
              "redirectUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
          } else {
            // window.location.href = "/dashboard";
            window.location.href = "/";
          }
        } else {
          const errorMessage = "Invalid email or password. Please try again.";
          setErrorMessage(errorMessage); // Update error message state
          addMessage("Invalid email or password. Please try again.");
          //console.log(addMessage);
        }
      })
      .catch((error) => {
        setLoadingState(false);
        const errorMessage = "An error occurred. Please try again later.";
        setErrorMessage(errorMessage); // Update error message state
        addMessage("An error occurred. Please try again later.");
        //console.log(addMessage);
      });
  };

  //console.log("Current Error Message:", errorMessage);

  return (
    <div className="login">
      {loading && <LoadingSpinner />}
      <div className="Loginpage-center">
        <h1 className="loginH1" >Log In</h1>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`input-ctrl ${
                isInvalidEmail(email) ? "error-class" : "noerror-class"
              }`}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">Password</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`input-ctrl ${
                isInvalidPassword(password) ? "error-class" : "noerror-class"
              }`}
              type={showPassword && password.length > 0 ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            {password.length > 0 && (
              <span
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            )}
          </div>
        </div>
        <div
          className="frgt-pswd cursor-pointer"
          onClick={() => {
            history.push("/reset-pwd");
          }}
        >
          Forget password?
        </div>
        <div className="login-btn">
          <button
            className="login-btn"
            onClick={handleLogin}
            disabled={loading}
          >
            Log In
          </button>
        </div>
        <div className="sign-up-link">
          <p className="in-case">Don’t have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up Now
          </p>
        </div>
        <div className="sign-up-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMessage: (message) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      }),
  };
};

export default Login;
