// import { configureStore, createSlice } from '@reduxjs/toolkit';

// const searchResultsSlice = createSlice({
//   name: 'searchResults',
//   initialState: {
//     searchResults: [],
//     searchTerm: '',
//     loading: false,
//     activeButton: 'blogs',
//     formOrder: null,
//   },
//   reducers: {
//     setSearchResults: (state, action) => {
//       state.searchResults = action.payload;
//     },
//     setSearchTerm: (state, action) => {
//       state.searchTerm = action.payload;
//     },
//     setSearchLoading: (state, action) => {
//       state.loading = action.payload;
//     },
//     setActiveButton: (state, action) => {
//       state.activeButton = action.payload;
//     },
//     setFormOrder: (state, action) => {
//       state.formOrder = action.payload;
//     },
//   },
// });

// export const { actions, reducer } = searchResultsSlice;

// const store = configureStore({
//   reducer: {
//     searchResults: reducer,
//   },
// });

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import { reducer as searchReducer } from './searchSlice';
import { reducer as messageReducer } from './reducers';

const store = configureStore({
  reducer: {
    searchResults: searchReducer,
    messages: messageReducer,
  },
});

export default store;
