import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Blog CSS/Modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleKeyDown = (e) => {
    // eslint-disable-next-line prefer-destructuring
    const { key } = e.nativeEvent;
    if (key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const body = document.body;
    if (isOpen) {
      body.classList.add('modal-open');
    } else {
      body.classList.remove('modal-open');
    }

    return () => {
      body.classList.remove('modal-open');
    };
  }, [isOpen]);

  return (
    <div
      className={`modal-container ${isOpen ? 'open' : ''}`}
      role="button"
      tabIndex={isOpen ? 0 : -1}
      onClick={handleClose}
      onKeyDown={handleKeyDown}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        <button
          type="button"
          className="close"
          onClick={handleClose}
          aria-label="Close"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
