import React, { useState, useEffect, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import FallbackSpinner from "./components/FallbackSpinner";
import NavBarWithRouter from "./components/NavBar";
import endpoints from "./constants/endpoints";
import LandingPage from "./components/LandingPage";
import Blog from "./components/Blog/BlogLandingPage";
import Login from "./pages/Login/Login";
import BlogDetails from "./components/Blog/BlogDetails";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Signup from "./pages/Signup/Signup";
import ValidateEmail from "./pages/ValidateEmail/ValidateEmail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ConfirmResetPassword from "./pages/ConfirmResetPassword/ConfirmResetPassword";

function MainApp() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(endpoints.routes, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  return (
    <div className="MainApp">
      <NavBarWithRouter />
      <Switch>
        <Route className="Apps" exact path="/" component={LandingPage} />
        <Route className="Apps" exact path="/skills" component={Skills} />
        <Route
          className="Apps"
          exact
          path="/experience"
          component={Experience}
        />
        <Route className="Apps" exact path="/projects" component={Projects} />

        <Route exact path="/blogs" component={Blog} />
        <Route exact path="/blogs/:blogId" component={BlogDetails} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/validate-email/:id" component={ValidateEmail} />
        <Route exact path="/reset-pwd" component={ResetPassword} />
        <Route exact path="/reset-pwd/:id" component={ConfirmResetPassword} />
      </Switch>

      {/* <main className="main">
        <Switch>
          <Suspense fallback={<FallbackSpinner />}>
            {data &&
              data.sections.map((route) => {
                const SectionComponent = React.lazy(() =>
                  import("./components/" + route.component)
                );
                return (
                  <Route
                    key={route.headerTitle}
                    path={route.path}
                    component={() => (
                      <SectionComponent header={route.headerTitle} />
                    )}
                  />
                );
              })}
          </Suspense>
        </Switch>
      </main> */}
    </div>
  );
}

export default MainApp;
