// reducers.js

import { ADD_MESSAGE, REMOVE_MESSAGE } from "./actions";

const initialState = {
  messages: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      console.log("Reducer: Adding message to state:", action.payload); // Log the message being added to state
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case REMOVE_MESSAGE:
      console.log("Reducer: Removing message from state"); // Log the action to remove message from state
      return {
        ...state,
        messages: state.messages.filter((msg) => msg !== action.payload),
      };
    default:
      return state;
  }
};
