// BlogDetails.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import Editor from "./Editor";
import LikeButton from "./LikeButton";
import CommentSection from "./CommentSection";
import {
  editBlog,
  deleteBlog,
  fetchBlogContent,
  fetchBlogDetails,
} from "../SearchApi";
import "./Blog CSS/SelectedBlog.css";
import LoadingSpinner from "../LoadingSpinner";

function BlogDetails({ userId, userName, scrollPosition }) {
  const { blogId } = useParams();
  const history = useHistory();
  const [blogDetails, setBlogDetails] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedBlog, setEditedBlog] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  ////console.log("blogId in Details ", blogId);

  useEffect(() => {
    const cookieDomainExp =
      window.location.hostname === "localhost" ? "localhost" : "ownsit.xyz";

    document.cookie = `redirectUrl=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${cookieDomainExp};`;

    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${cookieDomainExp};`;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchBlogContent(blogId, setSelectedBlog, setIsLoading);
    fetchBlogDetails(blogId, (blogDetailsResponse) => {
      setBlogDetails(blogDetailsResponse.blog);
      setIsAuthorized(blogDetailsResponse.authorized);
    });
  }, [blogId]);

  //console.log("blog ", blogDetails, "selectedBlog", selectedBlog);

  const handleEditorChange = (content) => {
    setEditedBlog((prevBlog) => ({ ...prevBlog, content }));
  };

  const handleEdit = () => {
    setEditMode(true);
    setEditedBlog({ ...selectedBlog });
  };

  const handleSaveEdit = async () => {
    setIsLoading(true);
    try {
      const updatedBlog = {
        ...selectedBlog,
        content: editedBlog.content,
      };

      // Update the blog content in the database
      const success = await editBlog(blogId, updatedBlog);

      if (success) {
        setIsLoading(false);
        ////console.log("Blog edited successfully");
        setSelectedBlog(updatedBlog); // Update the local state with the edited blog
        setEditMode(false);
      } else {
        console.error("Error editing blog");
      }
    } catch (error) {
      console.error("Error editing blog post:", error);
    }
  };

  const handleDeleteBlog = async () => {
    setIsLoading(true);
    try {
      if (blogId) {
        //console.log("Deleting blog with _id:", blogId);

        const success = await deleteBlog(blogId);

        if (success) {
          setIsLoading(false);
          // If deletion is successful, go back to the blog list
          handleBackToBlogs();
        }
      } else {
        console.error("No blog selected for deletion or blog ID is undefined");
      }
    } catch (error) {
      console.error("Error deleting blog post:", error);
    }
  };

  const handleBackToBlogs = () => {
    setIsLoading(true);
    window.scrollTo(0, scrollPosition);
    history.push("/blogs");
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", options);
  }

  return (
    <div className="SelectBlog-card-selectedM">
      {isLoading ? (
        <LoadingSpinner /> // Render the loading spinner if data is still loading
      ) : (
        <React.Fragment>
          <div className="SelectBlog-card-selected">
            <div className="selectblogtitle">
              <h2 className="selactbLogTitle">{blogDetails?.title}</h2>
            </div>
            <div className="Selectblogauthor-and-date">
              <div className="Selectblogauthor-container">
                Author:&nbsp; {blogDetails?.author}
              </div>

              <div className="Selectblogdate-container">
                Date:&nbsp; {formatDate(blogDetails?.date)}
              </div>
            </div>
            {editMode ? (
              <div className="selectBlogEdit">
                <Editor
                  value={editedBlog?.content}
                  onChange={handleEditorChange}
                  editMode={editMode}
                  editorStyles=""
                />
              </div>
            ) : (
              <div className="selectBlogEditinEdit">
                <Editor value={selectedBlog?.content} editorStyles="" />
                <div className="SelectBloglike-comment-container">
                  <LikeButton
                    className="SelectBlogLike"
                    blogId={blogId}
                    userId={userId}
                  />
                  <CommentSection
                    className="selectBlogComment"
                    blogId={blogId}
                    userId={userId}
                    userName={userName}
                  />
                </div>
              </div>
            )}
            <div className="selectBlogButtons">
              <div className="backBlogButtons">
                {isAuthorized && (
                  <>
                    {editMode ? (
                      <button
                        type="button"
                        className="saveBtn"
                        onClick={handleSaveEdit}
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="editBtn"
                        onClick={handleEdit}
                      >
                        Edit Blog
                      </button>
                    )}
                  </>
                )}
                <button
                  type="button"
                  className="backBtn"
                  onClick={handleBackToBlogs}
                >
                  Back to Blogs
                </button>
                {isAuthorized && (
                  <button
                    type="button"
                    className="deleteBtn"
                    onClick={handleDeleteBlog}
                  >
                    Delete Blog
                  </button>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

BlogDetails.propTypes = {
  blogId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default BlogDetails;
