import React from 'react';
import Home from './Home';
import About from './About';
import Skills from './Skills';
import Experience from './Experience';
import Projects from './Projects';
import Header from './Header';
import Footer from './Footer';

function LandingPage() {
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Skills />
      <Experience />
      <Projects />
      {/* <Blog /> */}
      <Footer />
    </div>
  );
}

export default LandingPage;
