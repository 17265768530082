/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Blog CSS/LikeComment.css";
import { fetchComments, submitComment } from "../SearchApi";

const CommentSection = ({ blogId, userId, userName }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [visibleComments, setVisibleComments] = useState(5);

  // const fetchComments = async () => {
  //   try {
  //     const response = await api(
  //       `http://localhost:5001/api/getComments/${blogId}`
  //     );
  //     if (response) {
  //       const commentsData = await response;
  //       // Sort comments by date in descending order
  //       const sortedComments = commentsData.comments.sort(
  //         (a, b) => new Date(b.date) - new Date(a.date)
  //       );
  //       setComments(sortedComments);
  //     } else {
  //       console.error(
  //         "Error fetching comments:",
  //         response.status,
  //         response.statusText
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching comments:", error);
  //   }
  // };

  const fetchCommentsData = async () => {
    try {
      const commentsData = await fetchComments(blogId);
      setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchCommentsData();
  }, [blogId]);

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", options);
  }

  // const handleCommentSubmit = async () => {
  //   try {
  //     // Implement comment submission functionality
  //     const response = await api(
  //       `http://localhost:5001/api/commentBlog/${blogId}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userId,
  //           userName,
  //           text: newComment,
  //           date: new Date(),
  //         }),
  //       }
  //     );

  //     if (response) {
  //       const newCommentData = await response;
  //       setComments((prevComments) => [
  //         newCommentData.updatedBlog,
  //         ...prevComments,
  //       ]);
  //       setNewComment("");

  //       // Fetch comments again to ensure the latest data
  //       fetchComments();
  //     } else {
  //       console.error(
  //         "Error submitting comment:",
  //         response.status,
  //         response.statusText
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error submitting comment:", error);
  //   }
  // };

  const handleCommentSubmit = async () => {
    try {
      const updatedBlog = await submitComment(
        blogId,
        userId,
        userName,
        newComment
      );
      setComments((prevComments) => [updatedBlog, ...prevComments]);
      setNewComment("");
      fetchCommentsData(); // Fetch comments again to ensure the latest data
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  return (
    <div className="CommentSection">
      <h3>
        {comments.length}
        &nbsp; Comments
      </h3>

      {comments.slice(0, visibleComments).map((comment) => (
        <div key={comment._id} className="commentContainer">
          <div className="commentUser">
            <p>{comment.userName}</p>
          </div>
          <div className="commentText">
            <p>{comment.text}</p>
          </div>
          <div className="commentTimestamp">
            <p>{formatDate(comment.date)}</p>
          </div>
        </div>
      ))}
      <div className="loadMore">
        {visibleComments < comments.length ? (
          <button
            type="button"
            className="loadeMoreButton"
            onClick={() => setVisibleComments((prev) => prev + 5)}
          >
            Load More Comments
          </button>
        ) : null}
      </div>
      <div className="commentInputSub">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
        />
        <button type="button" onClick={handleCommentSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

CommentSection.propTypes = {
  blogId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default CommentSection;
