import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="section-content-container">
      <p>
        {/* eslint-disable react/jsx-one-expression-per-line */}
        Copyright &copy; {currentYear} Jayant Sogani. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
