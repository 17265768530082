// LoadingSpinner.jsx
import React from "react";
import "../css/LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="loading-spinnerr">
        <div className="logo-container">
          <img src="/images/logo.PNG" alt="Logo" className="logoloder" />
        </div>
        <div className="lodingInLoding">
          <div className="lodingLoding">
            <h3> Loding </h3>
          </div>
          <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
