import React from 'react';
import Blog2 from './Blog2';
import Sidebar from '../LeftNavBar/Sidebar';
import './Blog CSS/BlogLandingPage.css';

function BlogLandingPage() {
  return (
    <div className="BlogLandingPage">
      <div className="Blogsidebar">
        <Sidebar />
      </div>
      <div className="BlogBlogBlog">
        <Blog2 />
      </div>
    </div>
  );
}

export default BlogLandingPage;
