import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSearchResults } from "../SearchApi";

export const searchAsync = createAsyncThunk(
  "search/searchAsync",
  async ({ term, activeButton }) => {
    try {
      const results = await fetchSearchResults({
        searchTerm: term,
        activeButton,
      });
      return results;
    } catch (error) {
      console.error("Error fetching search results:", error);
      throw error;
    }
  }
);

export const setSearchResults = (results) => ({
  type: "SET_SEARCH_RESULTS",
  payload: results,
});

export const setSearchTerm = (searchTerm) => ({
  type: "SET_SEARCH_TERM",
  payload: searchTerm,
});

export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const addMessage = (message) => {
  console.log("Adding message:", message); // Log the message being added
  return {
    type: ADD_MESSAGE,
    payload: message,
  };
};

export const removeMessage = (message) => ({
  type: REMOVE_MESSAGE,
  payload: message,
});
