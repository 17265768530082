export const getBaseApi = function () {
  if (window.location.hostname === "localhost") {
    return window.location.origin.split("300")[0] + "5001";
  }
  return "https://ownsit.xyz";
};

export const getMainUrl = function () {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3000";
  }
  return "https://ownsit.xyz";
};

export const showExceptions = function () {
  return window.location.hostname !== "ownsit.xyz";
};

export const isProd = function () {
  return window.location.hostname === "ownsit.xyz";
};

export const BackendAPIs = {
  auth: {
    login: "/api/login",
    menuItems: "/api/menuItems",
    self: "/api/self",
    changePassword: "/api/changePassword",
    signup: "/api/signup",
    validateEmail: "/api/validate-email",
    resetPwd: "/api/reset-pwd",
    resetPwdAction: "/api/reset-pwd-action",
  },
  admin: {
    add: "/admin/addAdmin",
    email: "/admin/getAdminEmail",
    delete: "/admin/delete",
    globalPropsTypes: "/admin/globalPropsTypes",
    globalPropsKeys: "/admin/globalPropsKeys",
    updateGlobalProps: "/admin/updateGlobalProps",
    scheduleTaskList: "/admin/schedule-task-list",
    scheduleTaskOffer: "/admin/schedule-task-offer",
    triggerBatchProcess: "/admin/triggerBatchProcess",
    grantTask: "/admin/grant-schedule-task",
    revokeTask: "/admin/revoke-schedule-task",
    getUsersList: "/admin/getUsersList",
    getSideNav: "/admin/getSideNav",
    updateSideNav: "/admin/updateSideNav",
    removeSideNav: "/admin/removeSideNav",
    setDefaultSideNav: "/admin/setDefaultSideNav",
    loginAsUser: "/admin/loginAsUser",
  },
  managerAccount: {
    addNewManager: "/managers/addNewManager",
    getManagers: "/managers/getManagers",
    updateManagerSideNav: "/managers/updateManagerSideNav",
    getManagerSideNav: "/managers/getManagerSideNav",
  },
  generic: {
    downloadXls: "/generic/downloadXls",
    getProfilePicture: "/generic/getProfilePicture",
    setProfilePicture: "/generic/setProfilePicture",
    getUserInfo: "/generic/getUserInfo",
  },
};
