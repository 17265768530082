import React, { useState } from "react";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { useHistory } from "react-router-dom";
import "./signUp.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignUp = ({ setLoading, addMessage }) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [name, setName] = useState("");
  let [confirmPassword, setCPassword] = useState("");
  let [loading, setLoadingState] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  let history = useHistory();

  let isInvalidPassword = (pwd) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  let isInvalidEmail = (email) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  let isInvalidName = (name) => {
    return !name || name.length < 3;
  };

  let isInvalidCPwd = (pwd, cPwd) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g) ||
      pwd !== cPwd
    );
  };

  const handleSignUp = () => {
    setLoadingState(true);
    let message = "";

    if (isInvalidEmail(email)) {
      message = "Invalid email address.";
    } else if (isInvalidPassword(password)) {
      message =
        "Password must be between 8 to 15 characters and contain at least one letter and one number.";
    } else if (isInvalidName(name)) {
      message = "Name must be at least 3 characters long.";
    } else if (isInvalidCPwd(password, confirmPassword)) {
      message = "Passwords do not match or do not meet requirements.";
    }

    if (message) {
      addMessage(message);
      setLoadingState(false);
      return;
    }

    postCall(setLoading)(getBaseApi() + BackendAPIs.auth.signup, {
      email,
      password,
      name,
      confirmPassword,
    })
      .then((resp) => {
        setLoadingState(false);
        if (resp.data) {
          toast("Signup successful! Please check your mail.");
          addMessage("Signup successful! Please check your mail.");
          setName("");
          setEmail("");
          setPassword("");
          setCPassword("");
        }
      })
      .catch((error) => {
        setLoadingState(false);
        addMessage("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="sign-up">
      <div className="Signpage-center">
        <h1 className="signUph1">Sign Up</h1>
        <div className="name">
          <div className="lbl">Full Name</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`Signupinput-ctrl${
                isInvalidName(name) ? " error-class" : " noerror-class"
              }`}
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Full Name"
            />
          </div>
        </div>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`Signupinput-ctrl${
                isInvalidEmail(email) ? " error-class" : " noerror-class"
              }`}
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">Password</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`Signupinput-ctrl${
                isInvalidPassword(password) ? " error-class" : " noerror-class"
              }`}
              type={showPassword && password.length > 0 ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
            {password.length > 0 && (
              <span
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            )}
          </div>
        </div>
        <div className="cnfm-pswd">
          <div className="lbl">Confirm Password</div>
          <div className="input-icon">
            <input
              autoComplete="off"
              className={`Signupinput-ctrl${
                isInvalidCPwd(password, confirmPassword)
                  ? " error-class"
                  : " noerror-class"
              }`}
              type={
                showPasswordC && confirmPassword.length > 0
                  ? "text"
                  : "password"
              }
              value={confirmPassword}
              onChange={(e) => {
                setCPassword(e.target.value);
              }}
              placeholder="Confirm Password"
            />
            {confirmPassword.length > 0 && (
              <span
                className="toggle-password"
                onClick={() => {
                  setShowPasswordC(!showPasswordC);
                }}
              >
                {showPasswordC ? <FaEye /> : <FaEyeSlash />}
              </span>
            )}
          </div>
        </div>
        <div className="signUp-btn">
          <button
            className="signUp-btn"
            onClick={handleSignUp}
            disabled={loading}
          >
            Sign Up
          </button>
        </div>
        <div className="login-link">
          <p className="in-case">Already have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/login");
            }}
          >
            Log In Here
          </p>
        </div>
        <div className="login-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
    addMessage: (message) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      }),
  };
};

export default SignUp;
