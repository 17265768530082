// apiUrls.js
const baseUrl = process.env.REACT_APP_API_BASE_URL;

//WorkSpace Urls
export const triedTemplatesUrl = `${baseUrl}/triedTemplates`;
export const deleteTemplateUrl = (templateId) =>
  `${baseUrl}/deleteTemplate/${templateId}`;
export const checkDomainUrl = (templateId) =>
  `${baseUrl}/checkDomainForTemplate/${templateId}`;
export const handleLiveClickUrl = (templateId) =>
  `${baseUrl}/handleLiveClick/${templateId}`;
export const getDomainsUrl = (templateId) =>
  `${baseUrl}/getDomainsForTemplate/${templateId}`;
export const getFormOrderUrl = (templateId) =>
  `${baseUrl}/getFormOrderNames/${templateId}`;

//Domain Urls
export const getDomainsForTemplateUrl = (templateId) =>
  `${baseUrl}/getDomainsForTemplate/${templateId}`;
export const deleteDomainUrl = (domainId) =>
  `${baseUrl}/deleteDomain/${domainId}`;
export const checkDomainUrlInDoamin = (domain) =>
  `${baseUrl}/checkDomain/${domain}`;
export const saveDomainInFormOrderAndDomainUrl = `${baseUrl}/saveDomainInFormOrderAndDomain`;

//FormContainer Urls
export const getFormOrderNamesUrl = (templateId) =>
  `${baseUrl}/getFormOrderNames/${templateId}`;
export const updateFormOrderUrl = `${baseUrl}/updateFormOrder`;
export const saveFormDataUrl = `${baseUrl}/saveFormData`;

//NavBar Urls
export const getNavFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/NavBar?templateId=${templateId}`;

//Home Urls
export const getHomeFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Home?templateId=${templateId}`;

//Social Urls
export const getSocialFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Social?templateId=${templateId}`;

//About Urls
export const getAboutFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/About?templateId=${templateId}`;

//Skills Urls
export const getSkillsFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Skills?templateId=${templateId}`;

//Education Urls
export const getEducationFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Education?templateId=${templateId}`;

//Experiance Urls
export const getExperiencesFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Experiences?templateId=${templateId}`;

//Projects Urls
export const getProjectsFormDataUrl = (templateId) =>
  `${baseUrl}/getFormData/Projects?templateId=${templateId}`;

//Cusomise Urls
//DynamicComponent
export const saveAndTryTemplateUrl = () => `${baseUrl}/saveAndTryTemplate`;

export const checkTemplateNameUrl = () => `${baseUrl}/checkTemplateName`;

export const updateTemplateNameUrl = () => `${baseUrl}/updateTemplateName`;

//BloglikeUrl
export const getLikes = (blogId) => `${baseUrl}/api/getLikes/${blogId}`;
export const getUser = (likedUserId) => `${baseUrl}/api/getUser/${likedUserId}`;
export const unlikeBlog = (blogId) => `${baseUrl}/api/unlikeBlog/${blogId}`;
export const likeBlog = (blogId) => `${baseUrl}/api/likeBlog/${blogId}`;
export const likeOrUnlikeBlog = (blogId) => `${baseUrl}/api/likeOrUnlikeBlog/${blogId}`;
