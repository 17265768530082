import interceptor from "./interceptor.js";

const api = async (url, options) => {
  try {
    if (!options) {
      options = {};
    }
    const modifiedOptions = await interceptor.request(options);
    const response = await fetch(url, modifiedOptions);

    //console.log('response ', response);

    if (response.ok) {
      try {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          // Parse JSON using .json() method
          const responseData = await response.json();
          return responseData;
        } else {
          // If content-type is not JSON, return response text
          return { nonJsonResponse: true, responseText: await response.text() };
        }
      } catch (error) {
        console.error("Error parsing response:", error);
        throw new Error("Error parsing response");
      }
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export default api;
