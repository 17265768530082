// Editor.jsx
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Blog CSS/Editor.css';
import PropTypes from 'prop-types';

const Editor = ({ value, onChange, editMode }) => (
  <div className={`editor-container ${!editMode ? 'hide-toolbar' : ''}`}>
    <ReactQuill
      value={value}
      onChange={onChange}
      readOnly={!editMode}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['blockquote', 'code-block'],
          [{ color: [] }, { background: [] }],
          ['link', 'image', 'video'],
          ['clean'],
          [{ font: [] }],
        ],
      }}
      formats={[
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'script',
        'align',
        'code-block',
        'color',
        'background',
        'link',
        'image',
        'video',
      ]}
    />
  </div>
);

Editor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default Editor;
